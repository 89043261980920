<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">Заявка #000000000001</p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->
            <el-form ref="form" :model="form" label-position="top">
                <div class="app-modal__body p-5 pb-0">
                    <div
                        class="timeline-items__right rounded-sm w-100 p-4 mb-2"
                    >
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item label="Дата поступления заявки">
                                    <el-date-picker
                                        type="date"
                                        placeholder="Дата поступления заявки"
                                        v-model="form.date1"
                                        style="width: 100%"
                                    ></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="Стадия">
                                    <el-select
                                        class="w-100"
                                        v-model="form.region"
                                        placeholder=" Новая"
                                    >
                                        <el-option
                                            label="Zone one"
                                            value="shanghai"
                                        ></el-option>
                                        <el-option
                                            label="Zone two"
                                            value="beijing"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <el-collapse v-model="activeNames" @change="handleChange">
                        <div class="timeline-items__right w-100 jmar">
                            <el-collapse-item title="Данные клиента" name="1">
                                <div class="rounded-sm w-100 p-4">
                                    <el-row :gutter="20">
                                        <el-col :span="8">
                                            <div class="puluss">
                                                <el-form-item
                                                    class="mb-2 w-100"
                                                    label="Наименование"
                                                >
                                                    <el-select
                                                        class="w-100"
                                                        v-model="form.region"
                                                        placeholder=" Наименование"
                                                    >
                                                        <el-option
                                                            label="Zone one"
                                                            value="shanghai"
                                                        ></el-option>
                                                        <el-option
                                                            label="Zone two"
                                                            value="beijing"
                                                        ></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-button
                                                    class="mb-2 ml-2"
                                                    @click="newCilent = true"
                                                >
                                                    +
                                                </el-button>
                                            </div>

                                            <el-form-item
                                                class="mb-2"
                                                label="Фамилия"
                                            >
                                                <el-input
                                                    v-model="form.name"
                                                ></el-input>
                                            </el-form-item>

                                            <el-form-item
                                                class="mb-2"
                                                label="Телефон 1"
                                            >
                                                <el-input
                                                    v-model="form.name"
                                                ></el-input>
                                            </el-form-item>

                                            <el-form-item
                                                class="mb-2"
                                                label="Регион"
                                            >
                                                <el-select
                                                    class="w-100"
                                                    v-model="form.region"
                                                    placeholder=" Регион"
                                                >
                                                    <el-option
                                                        label="Zone one"
                                                        value="shanghai"
                                                    ></el-option>
                                                    <el-option
                                                        label="Zone two"
                                                        value="beijing"
                                                    ></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>

                                        <el-col :span="8">
                                            <el-form-item
                                                class="mb-2"
                                                label="Тип"
                                            >
                                                <el-select
                                                    class="w-100"
                                                    v-model="form.regionq"
                                                    placeholder=" Тип"
                                                >
                                                    <el-option
                                                        label="Zone one"
                                                        value="shanghai"
                                                    ></el-option>
                                                    <el-option
                                                        label="Zone two"
                                                        value="beijing"
                                                    ></el-option>
                                                </el-select>
                                            </el-form-item>

                                            <el-form-item
                                                class="mb-2"
                                                label="Имя"
                                            >
                                                <el-input
                                                    v-model="form.nameq"
                                                ></el-input>
                                            </el-form-item>

                                            <el-form-item
                                                class="mb-2"
                                                label="Телефон 2"
                                            >
                                                <el-input
                                                    v-model="form.nameaw"
                                                ></el-input>
                                            </el-form-item>

                                            <el-form-item
                                                class="mb-2"
                                                label="Район"
                                            >
                                                <el-select
                                                    class="w-100"
                                                    v-model="form.regionq"
                                                    placeholder=" Район"
                                                >
                                                    <el-option
                                                        label="Zone one"
                                                        value="shanghai"
                                                    ></el-option>
                                                    <el-option
                                                        label="Zone two"
                                                        value="beijing"
                                                    ></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>

                                        <el-col :span="8">
                                            <el-form-item
                                                class="mb-2"
                                                label="Пол"
                                            >
                                                <el-select
                                                    class="w-100"
                                                    v-model="form.pol"
                                                    placeholder=" Пол"
                                                >
                                                    <el-option
                                                        label="Zone one"
                                                        value="shanghai"
                                                    ></el-option>
                                                    <el-option
                                                        label="Zone two"
                                                        value="beijing"
                                                    ></el-option>
                                                </el-select>
                                            </el-form-item>

                                            <el-form-item
                                                class="mb-2"
                                                label="Электронная почта"
                                            >
                                                <el-input
                                                    v-model="form.mail"
                                                ></el-input>
                                            </el-form-item>

                                            <el-form-item
                                                class="mb-2"
                                                label="Адрес"
                                            >
                                                <el-input
                                                    v-model="form.name"
                                                ></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-collapse-item>
                        </div>

                        <div class="timeline-items__right w-100 jmar">
                            <el-collapse-item
                                title="Информация о заявке"
                                class="mt-2"
                                name="2"
                            >
                                <div class="rounded-sm w-100 p-4">
                                    <el-tabs
                                        v-model="activeName"
                                        @tab-click="handleClick"
                                    >
                                        <el-tab-pane
                                            label="Заявка"
                                            name="first"
                                        >
                                            <el-row :gutter="20">
                                                <el-col :span="8">
                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Тип "
                                                    >
                                                        <el-select
                                                            class="mb-1 w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Тип "
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <div class="puluss">
                                                        <el-form-item
                                                            class="mb-1 w-100"
                                                            label="Серийный номер"
                                                        >
                                                            <el-input
                                                                placeholder=" Новая"
                                                                v-model="
                                                                    form.name
                                                                "
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-button
                                                            class="mb-1 ml-2"
                                                            @click="
                                                                newPraduct = true
                                                            "
                                                        >
                                                            +
                                                        </el-button>
                                                    </div>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Марка (Бренд)"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Марка (Бренд)"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Тип гарантии"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Тип гарантии"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Сервис"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Сервис"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Поставщик услуг (Мастер)"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Поставщик услуг (Мастер)"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Статус"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Статус"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Тип устройства"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Тип устройства"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Продукт"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Продукт"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Дата начала гарантии"
                                                    >
                                                        <el-date-picker
                                                            type="date"
                                                            placeholder="Дата начала гарантии"
                                                            v-model="form.date1"
                                                            style="width: 100%"
                                                        ></el-date-picker>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Предварительная дата"
                                                    >
                                                        <el-date-picker
                                                            type="date"
                                                            placeholder="Предварительная дата"
                                                            v-model="form.date1"
                                                            style="width: 100%"
                                                        ></el-date-picker>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Группа продуктов"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder="Группа продуктов"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Дата истечения срока гарантии"
                                                    >
                                                        <el-date-picker
                                                            type="date"
                                                            placeholder="Дата истечения срока гарантии"
                                                            v-model="form.date1"
                                                            style="width: 100%"
                                                        ></el-date-picker>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Комментарий"
                                                    >
                                                        <el-input
                                                            type="textarea"
                                                            v-model="form.desc"
                                                        ></el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                        <el-tab-pane
                                            label="Информация"
                                            name="second"
                                        >
                                            <el-row :gutter="20">
                                                <el-col :span="8">
                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Тип "
                                                    >
                                                        <el-select
                                                            class="mb-1 w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Тип "
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <div class="puluss">
                                                        <el-form-item
                                                            class="mb-1 w-100"
                                                            label="Серийный номер"
                                                        >
                                                            <el-input
                                                                placeholder=" Новая"
                                                                v-model="
                                                                    form.name
                                                                "
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-button
                                                            class="mb-1 ml-2"
                                                            @click="
                                                                newPraduct = true
                                                            "
                                                        >
                                                            +
                                                        </el-button>
                                                    </div>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Марка (Бренд)"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Марка (Бренд)"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Тип гарантии"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Тип гарантии"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Статус"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Статус"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Тип устройства"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Тип устройства"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Продукт"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Продукт"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Дата начала гарантии"
                                                    >
                                                        <el-date-picker
                                                            type="date"
                                                            placeholder="Дата начала гарантии"
                                                            v-model="form.date1"
                                                            style="width: 100%"
                                                        ></el-date-picker>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Группа продуктов"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder="Группа продуктов"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-5"
                                                        label="Дата истечения срока гарантии"
                                                    >
                                                        <el-date-picker
                                                            type="date"
                                                            placeholder="Дата истечения срока гарантии"
                                                            v-model="form.date1"
                                                            style="width: 100%"
                                                        ></el-date-picker>
                                                    </el-form-item>

                                                    <el-button
                                                        class="w-100 mt-5"
                                                        type="success"
                                                        @click="newHints = true"
                                                    >
                                                        Подсказки по устранению
                                                        неисправностей
                                                    </el-button>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                        <el-tab-pane
                                            label="Жалоба"
                                            name="third"
                                        >
                                            <el-row :gutter="20">
                                                <el-col :span="8">
                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Тип "
                                                    >
                                                        <el-select
                                                            class="mb-1 w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Тип "
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <div class="puluss">
                                                        <el-form-item
                                                            class="mb-1 w-100"
                                                            label="Серийный номер"
                                                        >
                                                            <el-input
                                                                placeholder=" Новая"
                                                                v-model="
                                                                    form.name
                                                                "
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-button
                                                            class="mb-1 ml-2"
                                                            @click="
                                                                newPraduct = true
                                                            "
                                                        >
                                                            +
                                                        </el-button>
                                                    </div>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Марка (Бренд)"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Марка (Бренд)"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Тип гарантии"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Тип гарантии"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>

                                                <el-col :span="8">
                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Статус"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Статус"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Тип устройства"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Тип устройства"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Продукт"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Продукт"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Дата начала гарантии"
                                                    >
                                                        <el-date-picker
                                                            type="date"
                                                            placeholder="Дата начала гарантии"
                                                            v-model="form.date1"
                                                            style="width: 100%"
                                                        ></el-date-picker>
                                                    </el-form-item>
                                                </el-col>

                                                <el-col :span="8">
                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Группа продуктов"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder="Группа продуктов"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Дата истечения срока гарантии"
                                                    >
                                                        <el-date-picker
                                                            type="date"
                                                            placeholder="Дата истечения срока гарантии"
                                                            v-model="form.date1"
                                                            style="width: 100%"
                                                        ></el-date-picker>
                                                    </el-form-item>

                                                    <el-form-item
                                                        label="Комментарий"
                                                    >
                                                        <el-input
                                                            type="textarea"
                                                            v-model="form.desc"
                                                        ></el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>
                            </el-collapse-item>
                        </div>

                        <div class="timeline-items__right w-100 jmar">
                            <el-collapse-item
                                title="Услуги и расходные материалы"
                                class="mt-2"
                                name="3"
                            >
                                <div class="rounded-sm w-100 p-4">
                                    <el-row :gutter="20">
                                        <el-col :span="8">
                                            <el-form-item
                                                class="mb-1"
                                                label="Место "
                                            >
                                                <el-select
                                                    class="mb-1 w-100"
                                                    v-model="form.baq"
                                                    placeholder=" Место "
                                                >
                                                    <el-option
                                                        label="Zone one"
                                                        value="shanghai"
                                                    ></el-option>
                                                    <el-option
                                                        label="Zone two"
                                                        value="beijing"
                                                    ></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item
                                                class="mb-1"
                                                label="Выявленные ошибки (сбои)"
                                            >
                                                <el-select
                                                    class="w-100"
                                                    v-model="form.ba"
                                                    placeholder=" Выявленные ошибки (сбои)"
                                                >
                                                    <el-option
                                                        label="Zone one"
                                                        value="shanghai"
                                                    ></el-option>
                                                    <el-option
                                                        label="Zone two"
                                                        value="beijing"
                                                    ></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item
                                                class="mb-1"
                                                label="Комментарий"
                                            >
                                                <el-input
                                                    type="textarea"
                                                    v-model="form.desc"
                                                ></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-tabs
                                        type="card"
                                        @tab-click="handleClick"
                                    >
                                        <el-tab-pane label="Услуги">
                                            <el-row :gutter="20">
                                                <el-col :span="8">
                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Услуги"
                                                    >
                                                        <el-select
                                                            class="mb-4 w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Услуги"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                        <el-select
                                                            class="w-100"
                                                            v-model="form.ba"
                                                            placeholder=" Услуги"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item
                                                        class="mb-1"
                                                        label=" Цена"
                                                    >
                                                        <el-input
                                                            v-model="form.desc"
                                                        ></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <div
                                                        class="d-flex"
                                                        style="align-items: end"
                                                    >
                                                        <el-form-item
                                                            class="mb-1 ml-2"
                                                            label=" Сумма"
                                                        >
                                                            <el-input
                                                                v-model="
                                                                    form.desc
                                                                "
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="mb-1 ml-2"
                                                            label=" Валюта"
                                                        >
                                                            <el-input
                                                                v-model="
                                                                    form.desc
                                                                "
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-button
                                                            class="ml-2"
                                                            type="danger"
                                                            icon="el-icon-delete"
                                                            circle
                                                        ></el-button>
                                                    </div>

                                                    <h3 class="mt-3">
                                                        <b
                                                            >Итого: 100 000
                                                            сум</b
                                                        >
                                                    </h3>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                        <el-tab-pane
                                            label="Расходные материалы"
                                        >
                                            <el-row :gutter="20">
                                                <el-col :span="8">
                                                    <el-form-item
                                                        class="mb-1"
                                                        label="Расходные материалы"
                                                    >
                                                        <el-input
                                                            v-model="form.desc"
                                                            placeholder="  Фреон"
                                                        ></el-input>
                                                        <el-select
                                                            class="w-100 mt-4"
                                                            v-model="form.ba"
                                                            placeholder="  Добавить позицию"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <div class="d-flex">
                                                        <el-form-item
                                                            class="mb-1"
                                                            label=" Кол-во"
                                                        >
                                                            <el-input
                                                                v-model="
                                                                    form.desc
                                                                "
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="mb-1 ml-2"
                                                            label=" Ед. измерения"
                                                        >
                                                            <el-input
                                                                v-model="
                                                                    form.desc
                                                                "
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="mb-1 ml-2"
                                                            label=" Цена"
                                                        >
                                                            <el-input
                                                                v-model="
                                                                    form.desc
                                                                "
                                                            ></el-input>
                                                        </el-form-item>
                                                    </div>
                                                </el-col>
                                                <el-col :span="8">
                                                    <div
                                                        class="d-flex"
                                                        style="align-items: end"
                                                    >
                                                        <el-form-item
                                                            class="mb-1 ml-2"
                                                            label=" Сумма"
                                                        >
                                                            <el-input
                                                                v-model="
                                                                    form.desc
                                                                "
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="mb-1 ml-2"
                                                            label=" Валюта"
                                                        >
                                                            <el-input
                                                                v-model="
                                                                    form.desc
                                                                "
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-button
                                                            class="ml-2"
                                                            type="danger"
                                                            icon="el-icon-delete"
                                                            circle
                                                        ></el-button>
                                                    </div>

                                                    <h3 class="mt-3">
                                                        <b
                                                            >Итого: 100 000
                                                            сум</b
                                                        >
                                                    </h3>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>
                            </el-collapse-item>
                        </div>
                    </el-collapse>
                </div>
                <!-- end app-modal__body -->
            </el-form>
        </div>

        <el-dialog
            title="Новый клиент"
            :visible.sync="newCilent"
            width="50%"
            :before-close="handleClose"
            :append-to-body="true"
        >
            <span>
                <newCilent></newCilent>
            </span>
        </el-dialog>

        <el-dialog
            title="Новый продукт"
            :visible.sync="newPraduct"
            width="50%"
            :before-close="handleClose"
            :append-to-body="true"
        >
            <span>
                <newPraduct></newPraduct>
            </span>
        </el-dialog>

        <el-dialog
            title="Подсказки по устранению неисправностей"
            :visible.sync="newHints"
            width="70%"
            :before-close="handleClose"
            :append-to-body="true"
        >
            <span>
                <newHints></newHints>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import newCilent from "./new-cilent";
import newPraduct from "./new-praduct.vue";
import newHints from "./new-hints.vue";
export default {
    components: {
        newCilent,
        newPraduct,
        newHints,
    },
    data() {
        return {
            newCilent: false,
            newPraduct: false,
            newHints: false,
            radio: "1",
            activeName: "first",
            form: {},
            activeNames: [""],
        };
    },
};
</script>
