<template>
    <div class="">
        <div
            class="card-table"
            :class="mode ? 'card__tableday' : 'card__tablenight'"
        >
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div
                                :class="
                                    mode
                                        ? 'content__titleday'
                                        : 'content__titlenight'
                                "
                                class="content-title d-flex align-center mr-2"
                            >
                                Заявки
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :className="'w100'"
                                        :class="
                                            mode ? 'input__day' : 'input__night'
                                        "
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            @c-create="drawerCreate = true"
                            :class="
                                mode
                                    ? 'button__settingsday'
                                    : 'button__settingsnight'
                            "
                            :columns="columns"
                            @c-change="updateColumn"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div>
                <ul class="nav-item-top">
                    <li>
                        <router-link
                            class="nav-item-top-link"
                            :to="{ name: 'texnoApplicationsAll' }"
                        >
                            Все
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item-top-link"
                            :to="{ name: 'texnoApplicationsNew' }"
                        >
                            Новые
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item-top-link"
                            :to="{ name: 'texnoApplicationsAccepted' }"
                        >
                            Принятые
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item-top-link"
                            :to="{ name: 'texnoApplicationsWaiting' }"
                        >
                            В ожидании зап. частей
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item-top-link action"
                            :to="{ name: 'texnoApplicationsProcess' }"
                        >
                            В процессе
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item-top-link"
                            :to="{ name: 'texnoApplicationsCompleted' }"
                        >
                            Завершенные
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item-top-link bg-red"
                            :to="{ name: '/' }"
                        >
                            Отказанные
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="card-table-header table-crm-smart">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.napeProduct.show">
                                {{ columns.napeProduct.title }}
                            </th>

                            <th v-if="columns.cilent.show">
                                {{ columns.cilent.title }}
                            </th>

                            <th v-if="columns.serialNumber.show">
                                {{ columns.serialNumber.title }}
                            </th>

                            <th v-if="columns.typeOrder.show">
                                {{ columns.typeOrder.title }}
                            </th>

                            <th v-if="columns.typeA.show">
                                {{ columns.typeA.title }}
                            </th>

                            <th v-if="columns.DateManufacture.show">
                                {{ columns.DateManufacture.title }}
                            </th>

                            <th v-if="columns.dateSale.show">
                                {{ columns.dateSale.title }}
                            </th>

                            <th v-if="columns.warrantyExpiration.show">
                                {{ columns.warrantyExpiration.title }}
                            </th>

                            <th v-if="columns.cdata.show">
                                {{ columns.cdata.title }}
                            </th>

                            <th v-if="columns.proStatus.show">
                                {{ columns.proStatus.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.napeProduct.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.napeProduct"
                                    :placeholder="columns.napeProduct.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.cilent.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.cilent"
                                    :placeholder="columns.cilent.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.serialNumber.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.serialNumber"
                                    :placeholder="columns.serialNumber.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.typeOrder.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.typeOrder"
                                    :placeholder="columns.typeOrder.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.typeA.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.typeA"
                                    :placeholder="columns.typeA.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.DateManufacture.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.DateManufacture"
                                    :placeholder="columns.DateManufacture.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.dateSale.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.dateSale"
                                    :placeholder="columns.dateSale.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.warrantyExpiration.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.warrantyExpiration"
                                    :placeholder="
                                        columns.warrantyExpiration.title
                                    "
                                ></el-input>
                            </th>

                            <th v-if="columns.cdata.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.cdata"
                                    :placeholder="columns.cdata.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.proStatus.show"></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="user in list"
                            :key="user.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">{{ user.id }}</td>

                            <td v-if="columns.napeProduct.show">
                                {{ user.napeProduct }}
                            </td>

                            <td v-if="columns.cilent.show">
                                {{ user.cilent }}
                            </td>

                            <td v-if="columns.serialNumber.show">
                                {{ user.serialNumber }}
                            </td>

                            <td v-if="columns.typeOrder.show">
                                {{ user.typeOrder }}
                            </td>

                            <td v-if="columns.typeA.show">
                                {{ user.typeA }}
                            </td>

                            <td v-if="columns.DateManufacture.show">
                                {{ user.DateManufacture }}
                            </td>

                            <td v-if="columns.dateSale.show">
                                {{ user.dateSale }}
                            </td>

                            <td v-if="columns.warrantyExpiration.show">
                                {{ user.warrantyExpiration }}
                            </td>

                            <td v-if="columns.cdata.show">
                                {{ user.cdata }}
                            </td>

                            <td v-if="columns.proStatus.show">
                                <el-button
                                    @click="drawerCreate = true"
                                    type="success"
                                    n
                                    size="mini"
                                >
                                    Завершение 5%
                                </el-button>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="app-modal app-modal__full mg scrol">
                    <el-drawer
                        :with-header="false"
                        :visible.sync="drawerCreate"
                        size="97%"
                    >
                        <div>
                            <CrmCreate></CrmCreate>
                        </div>
                    </el-drawer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CrmCreate from "./components/crm-create";
export default {
    name: "texnoApplicationsProcess",
    components: {
        CrmCreate,
    },
    data() {
        return {
            drawerCreate: false,
            filterForm: {
                id: "",
                napeProduct: "",
                cilent: "",
                serialNumber: "",
                typeOrder: "",
                typeA: "",
                DateManufacture: "",
                dateSale: "",
                warrantyExpiration: "",
                cdata: "",
                proStatus: "",
            },
            list: [
                {
                    id: "1",
                    napeProduct: "Оператор 1",
                    cilent: " Болтаев Теша",
                    serialNumber: "2020-01-01 12:59",
                    typeOrder: "Заявка",
                    typeA: "Ремонт",
                    DateManufacture: "Лифт",
                    dateSale: "Тешавой ака",
                    warrantyExpiration: "2020-01-01 12:59",
                    cdata: "2020-01-01 12:59",
                },
            ],
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

                napeProduct: {
                    show: true,
                    title: "Оператор",
                    sortable: true,
                    column: "napeProduct",
                },

                cilent: {
                    show: true,
                    title: "Клиент",
                    sortable: true,
                    column: "cilent",
                },

                serialNumber: {
                    show: true,
                    title: "Дата поступления заявки",
                    sortable: true,
                    column: "serialNumber",
                },

                typeOrder: {
                    show: true,
                    title: "Тип заявки",
                    sortable: true,
                    column: "typeOrder",
                },
                typeA: {
                    show: true,
                    title: "Тип",
                    sortable: true,
                    column: "typeA",
                },

                DateManufacture: {
                    show: true,
                    title: "Тип (заявки, информации, жалобы)",
                    sortable: true,
                    column: "DateManufacture",
                },

                dateSale: {
                    show: true,
                    title: "Поставщик услуг (Мастер)",
                    sortable: true,
                    column: "dateSale",
                },

                warrantyExpiration: {
                    show: true,
                    title: "Дата создания",
                    sortable: true,
                    column: "warrantyExpiration",
                },

                cdata: {
                    show: true,
                    title: "Дата изменения",
                    sortable: true,
                    column: "cdata",
                },

                proStatus: {
                    show: true,
                    title: "Статус",
                    sortable: true,
                    column: "proStatus",
                },
            },
        };
    },
};
</script>

